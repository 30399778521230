<template>
  <AppointmentSelectPayment />
</template>

<script>
import AppointmentSelectPayment from '@/components/appointments/book/AppointmentSelectPayment'
export default {
  name: 'AppointmentPayment',

  components: { AppointmentSelectPayment },
}
</script>
