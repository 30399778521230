<template>
  <AppointmentSelectPatient />
</template>

<script>
import AppointmentSelectPatient from '@/components/appointments/book/AppointmentSelectPatient'

export default {
  name: 'SelectPatient',

  components: { AppointmentSelectPatient },
}
</script>
