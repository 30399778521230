<template>
  <div>
    <AppointmentSelectClinic />
  </div>
</template>

<script>
import AppointmentSelectClinic from '@/components/appointments/book/AppointmentSelectClinic'

export default {
  name: 'ClinicsServices',

  components: { AppointmentSelectClinic },

}
</script>
