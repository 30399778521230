<template>
  <AppointmentSelectNotes />
</template>

<script>
import AppointmentSelectNotes from '@/components/appointments/book/AppointmentSelectNotes'

export default {
  name: 'AppointmentNotes',

  components: { AppointmentSelectNotes },
}
</script>
