<template>
  <AppointmentSummaryDetail />
</template>

<script>
import AppointmentSummaryDetail from '@/components/appointments/book/AppointmentSummaryDetail'

export default {
  name: 'AppointmentSummary',

  components: { AppointmentSummaryDetail },
}
</script>
